/*
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
* * * * * ==============================
========================================
========================================
========================================
----------------------------------------
USWDS 2.10.0
----------------------------------------
SPACING SETTINGS
----------------------------------------
Read more about settings and
USWDS spacing units tokens in the
documentation:
https://designsystem.digital.gov/design-tokens/spacing-units
----------------------------------------
*/

/*
----------------------------------------
Border radius
----------------------------------------
2px           2px
0.5           4px
1             8px
1.5           12px
2             16px
2.5           20px
3             24px
4             32px
5             40px
6             48px
7             56px
8             64px
9             72px
----------------------------------------
*/

$theme-border-radius-sm: 2px;
$theme-border-radius-md: 0.5;
$theme-border-radius-lg: 1;

/*
----------------------------------------
Column gap
----------------------------------------
2px         2px
0.5         4px
1           8px
2           16px
3           24px
4           32px
5           40px
6           48px
----------------------------------------
*/

$theme-column-gap-sm: 2px;
$theme-column-gap-md: 2;
$theme-column-gap-lg: 3;

// These determine the responsive gap sizes set with .grid-gap
$theme-column-gap-mobile: 1;
$theme-column-gap-desktop: 2;

/*
----------------------------------------
Grid container max-width
----------------------------------------
mobile
mobile-lg
tablet
tablet-lg
desktop
desktop-lg
widescreen
----------------------------------------
*/

$theme-grid-container-max-width: "desktop-lg";

/*
----------------------------------------
Site
----------------------------------------
*/

$theme-site-max-width: "desktop-lg";
$theme-site-margins-breakpoint: "desktop-lg";
$theme-site-margins-width: 4;
$theme-site-margins-mobile-width: 2;
