// Edited based on https://www.epa.gov/sites/all/themes/epa/css/drop-down-menu.min.css

nav.main-nav>.nav__inner>.menu .menu {
  position: absolute;
  z-index: 4
}

nav.main-nav>.nav__inner>.menu .menu .menu-link {
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;
  position: absolute !important
}

nav.main-nav .menu-link:focus+.menu>.menu-item>.menu-link,
nav.main-nav .show-menu .menu>.menu-item>.menu-link,
nav.main-nav>.nav__inner>.menu .menu .menu-link:focus,
html.no-js nav.main-nav .menu-item:hover>.menu>.menu-item>.menu-link {
  clip: auto;
  opacity: 1;
  position: static !important
}

nav.main-nav>.nav__inner>.menu .menu {
  background: #f1f1f1;
  display: ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  max-width: 19.7647em
}

nav.main-nav>.nav__inner>.menu .menu .menu-item {
  display: grid
}

nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(1):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(1):first-child~li,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(2):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(2):first-child~li,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(3):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(3):first-child~li,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(4):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(4):first-child~li,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(5):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(5):first-child~li,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(6):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(6):first-child~li,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(7):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(7):first-child~li,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(8):first-child,
nav.main-nav>.nav__inner>.menu .menu .menu-item:nth-last-child(8):first-child~li {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1/span 2
}

nav.main-nav>.nav__inner>.menu .menu .menu-link {
  align-items: center;
  background: #f1f1f1;
  border: solid #f1f1f1;
  border-width: 0 5px;
  color: #205493;
  display: flex;
  font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  line-height: 1.1;
  text-decoration: none;
  width: 12em
}

nav.main-nav>.nav__inner>.menu .menu a.active,
nav.main-nav>.nav__inner>.menu .menu a.menu-link:active,
nav.main-nav>.nav__inner>.menu .menu a.menu-link:focus,
nav.main-nav>.nav__inner>.menu .menu a.menu-link:hover {
  background: #d6d7d9;
  border-color: #205493;
  outline: 0
}

nav.main-nav>.nav__inner>.menu .menu a.active {
  font-weight: 700
}
